import request from '@/utils/request';
import axios from 'axios';
import { getTokens } from '@/utils/auth';
// import data from '@/views/icons/svg-icons/generateIconsView';
//zzy
export function MyCustomerIndex(data) {
  return request({
    url: '/clientserver.php/MyCustomer/index',
    method: 'post',
    data: data
  });
}
//zzy
export function MyFollowIndex(data) {
  return request({
    url: '/clientserver.php/Follow/index',
    method: 'post',
    data: data
  });
}
//财务待审核订单列表
export function getFinancialAuditPageList(data) {
  return request({
    url: '/clientserver.php/CustomerOrder/getFinancialAuditPageList',
    method: 'post',
    data: data
  });
}
//财务审核通过订单列表
export function getFinancialAudit(data) {
  return request({
    url: '/clientserver.php/CustomerOrder/getFinancialAuditStatusSuccessPageList',
    method: 'post',
    data: data
  });
}
//财务已审核列表
export function getAuditedPageList(data) {
  return request({
    url: '/clientserver.php/CustomerOrder/getFinancialOrderAuditedPageList',
    method: 'post',
    data: data
  });
}
//已提交订单列表
export function OrderSubmitted(data) {
  return request({
    url: '/clientserver.php/CustomerOrder/list',
    method: 'post',
    data: data
  });
}
//审核中订单列表
export function getAuditStatus(data) {
  return request({
    url: '/clientserver.php/CustomerOrder/getAuditStatusAwaitPageList',
    method: 'post',
    data: data
  });
}
//提交订单详情
export function SubOrderDetail(data) {
  return request({
    url: '/clientserver.php/CustomerOrder/read',
    method: 'post',
    data: data
  });
}
//已流转客户列表
export function MyCirculationList(data) {
  return request({
    url: '/clientserver.php/MyCustomer/exchange_list',
    method: 'post',
    data: data
  });
}
export function MyLoweIndex(data) {
  return request({
    url: '/clientserver.php/MyLowe/index',
    method: 'post',
    data: data
  });
}
export function getCustomerLevel(data) {
  return request({
    url: '/clientserver.php/Common/get_customer_level',
    method: 'post',
    data: data
  });
}
export function MyCustomerRegression(data) {
  return request({
    url: '/clientserver.php/MyCustomer/regression',
    method: 'post',
    data: data
  });
}
export function MyCustomerTop(data) {
  return request({
    url: '/clientserver.php/MyCustomer/top',
    method: 'post',
    data: data
  });
}
export function MyCustomerTransfer(data) {
  return request({
    url: '/clientserver.php/MyCustomer/transfer',
    method: 'post',
    data: data
  });
}
export function MyCustomerTransfoll(data) {
  return request({
    url: '/clientserver.php/MyCustomer/transfoll',
    method: 'post',
    data: data
  });
}
export function GetPhoneAddress(data) {
  return request({
    url: '/clientserver.php/Common/get_phone_address',
    method: 'post',
    data: data
  });
}
export function getGraduationMajor() {
  return request({
    url: '/clientserver.php/Common/get_graduation_xueli',
    method: 'post'
  });
}
export function getIttnxm() {
  return request({
    url: '/clientserver.php/Common/get_ittnxm',
    method: 'post'
  });
}
export function getEntrance() {
  return request({
    url: '/clientserver.php/Common/getEntrance',
    method: 'post'
  });
}
export function getLabelpeer() {
  return request({
    url: '/clientserver.php/Common/get_labelpeer',
    method: 'post'
  });
}
export function MyCustomerAdd(data) {
  return request({
    url: '/clientserver.php/MyCustomer/add',
    method: 'post',
    data: data
  });
}
// 外送项目方
export function agentFlower(data) {
  return request({
    url: '/clientserver.php/Common/agentFlowerNameList',
    method: 'post',
    data: data
  });
}
export function getShareList(data) {
  return request({
    url: '/clientserver.php/Common/getShareList',
    method: 'post',
    data: data
  });
}
export function sendcustomer(data) {
  return request({
    url: '/clientserver.php/CustomerDeliver/sendcustomer',
    method: 'post',
    data: data
  });
}
export function Customerinfo(data) {
  return request({
    url: '/clientserver.php/MyCustomer/customerinfo',
    method: 'post',
    data: data
  });
}
export function MyCustomerUpdate(data) {
  return request({
    url: '/clientserver.php/MyCustomer/update',
    method: 'post',
    data: data
  });
}
export function MyShareSetshare(data) {
  return request({
    url: '/clientserver.php/MyShare/setshare',
    method: 'post',
    data: data
  });
}
export function FollwupIndex(data) {
  return request({
    url: '/clientserver.php/Follwup/index',
    method: 'post',
    data: data
  });
}
//全部数据跟进记录
export function FollowIndex(data) {
  return request({
    url: '/clientserver.php/MyLowe/get_follow_log',
    method: 'post',
    data: data
  });
}
//全部客户所有跟进记录
export function FollowAll(data) {
  return request({
    url: '/clientserver.php/AllCustomer/get_follow_log',
    method: 'post',
    data: data
  });
}
//全部数据删除
export function delCustomer(data) {
  return request({
    url: '/clientserver.php/MyLowe/del_customer',
    method: 'post',
    data: data
  });
}
export function FollwupAdd(data) {
  return request({
    url: '/clientserver.php/Follwup/add',
    method: 'post',
    data: data
  });
}
export function MyCustomerCustomerlog(data) {
  return request({
    url: '/clientserver.php/MyCustomer/customerlog',
    method: 'post',
    data: data
  });
}
export function agentFlowerNameList(data) {
  return request({
    url: '/clientserver.php/Common/agentFlowerNameList',
    method: 'post',
    data: data
  });
}
export function MycustomerRegression(data) {
  return request({
    url: '/clientserver.php/MyCustomer/regression',
    method: 'post',
    data: data
  });
}
export function MyCustomerImport(data) {
  return request({
    url: '/clientserver.php/MyCustomer/import',
    method: 'post',
    data: data
  });
}
export function MyExcelModel(data) {
  return axios({
    url: process.env.VUE_APP_BASE_API2 + '/clientserver.php/MyCustomer/downloadStandardExcel',
    method: 'post',
    headers: {
      'content-type': 'application/octet-stream,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/json;charset=UTF-8',
      'token': getTokens()
    },
    data: data,
    responseType: 'blob'
  });
}
export function CustomerDeliverIndex(data) {
  return request({
    url: '/clientserver.php/CustomerDeliver/index',
    method: 'post',
    data: data
  });
}
export function MyCustomerSetlevel(data) {
  return request({
    url: '/clientserver.php/MyCustomer/setlevel',
    method: 'post',
    data: data
  });
}
export function MyStatisticsIndex(data) {
  return request({
    url: '/clientserver.php/MyStatistics/index',
    method: 'post',
    data: data
  });
}
export function CustomerStatistics() {
  return request({
    url: '/clientserver.php/CustomerStatistics/index',
    method: 'post'
  });
}

//简章列表zzy
export function BrochuresList(data) {
  return request({
    url: '/clientserver.php/common/brochuresList',
    method: 'post',
    data: data
  });
}
//专业列表zzy
export function Majorlist(data) {
  return request({
    url: '/clientserver.php/common/majorList',
    method: 'post',
    data: data
  });
}
//获取是否参与收费班级
export function classList(data) {
  return request({
    url: '/clientserver.php/Common/classList',
    method: 'post',
    data: data
  });
}
//院校管理列表zzy
export function School(data) {
  return request({
    url: '/clientserver.php/common/schoolList',
    method: 'post',
    data: data
  });
}
//手机号撞单zzy
export function checkmobile(data) {
  return request({
    url: '/clientserver.php/MyCustomer/checkmobile',
    method: 'post',
    data: data
  });
}
//批量修改回访时间zzy
export function FollwupSettime(data) {
  return request({
    url: '/clientserver.php/Follwup/settime',
    method: 'post',
    data: data
  });
}
// 客户详情订单列表
export function orderlist(data) {
  return request({
    url: '/clientserver.php/CustomerOrder/orderlist',
    method: 'post',
    data: data
  });
}
// 外呼
export function callout(data) {
  return request({
    url: '/clientserver.php/CallTianRun/callout',
    method: 'post',
    data: data
  });
}
// 挂断
export function unlink(data) {
  return request({
    url: '/clientserver.php/CallTianRun/unlink',
    method: 'post',
    data: data
  });
}
// 呼叫中心列表
export function getCallLog(data) {
  return request({
    url: '/clientserver.php/CallTianRun/getCallLog',
    method: 'post',
    data: data
  });
}
// 推广数据留存列表
export function RetainedCustomerList(data) {
  return request({
    url: '/clientserver.php/DistributionRetainedCustomer/retained_customer_list',
    method: 'post',
    data: data
  });
}
// 推广数据留存批量分配
export function UpdateDistributionStatus(data) {
  return request({
    url: '/clientserver.php/DistributionRetainedCustomer/update_distribution_status',
    method: 'post',
    data: data
  });
}

// 接收客户信息详情
export function CustomerReceiveInfo(data) {
  return request({
    url: '/clientserver.php/CustomerReceive/info',
    method: 'post',
    data: data
  });
}
// 呼叫中心列表
export function callCenterList(data) {
  return request({
    url: '/clientserver.php/CallMaster/index',
    method: 'post',
    data: data
  });
}
// 呼叫记录
export function callList(data) {
  return request({
    url: '/clientserver.php/CallMaster/getListByCustomerid',
    method: 'post',
    data: data
  });
}
// 呼叫中心通话录音处理：文件地址及下载
export function getAudioUrl(data) {
  return request({
    url: '/clientserver.php/CallMaster/getAudioUrl',
    method: 'post',
    data: data
  });
}
// 外呼检测次数
export function callNum(data) {
  return request({
    url: '/clientserver.php/CallMaster/callNum',
    method: 'post',
    data: data
  });
}
// 线下数据分配列表
export function CustomerDistribution(data) {
  return request({
    url: '/clientserver.php/CustomerDistribution/index',
    method: 'post',
    data: data
  });
}
// 线下数据分配新建
export function CustomerDistributionAdd(data) {
  return request({
    url: '/clientserver.php/CustomerDistribution/add',
    method: 'post',
    data: data
  });
}
// 线下数据分配编辑
export function CustomerDistributionEdit(data) {
  return request({
    url: '/clientserver.php/CustomerDistribution/exit',
    method: 'post',
    data: data
  });
}
// 线下数据分配删除
export function CustomerDistributionDelete(data) {
  return request({
    url: '/clientserver.php/CustomerDistribution/delete',
    method: 'post',
    data: data
  });
}
// 线下数据分配读取
export function CustomerDistributionRead(data) {
  return request({
    url: '/clientserver.php/CustomerDistribution/read',
    method: 'post',
    data: data
  });
}
// 线下数据分配获取部门人员
export function getPeople(data) {
  return request({
    url: '/clientserver.php/Structure/getpeople',
    method: 'post',
    data: data
  });
}

//已驳回订单列表
export function getRejectList(data) {
  return request({
    url: '/clientserver.php/CustomerOrder/getAuditStatusRejectPageList',
    method: 'post',
    data: data
  });
}
// 主管审核订单列表
export function spervisorAuditList(data) {
  return request({
    url: '/clientserver.php/CustomerOrder/getSpervisorAuditPageList',
    method: 'post',
    data: data
  });
}
//已通过订单列表
export function getSuccessList(data) {
  return request({
    url: '/clientserver.php/CustomerOrder/getAuditStatusSuccessPageList',
    method: 'post',
    data: data
  });
}
// 批量通过审核订单
export function batchAudit(data) {
  return request({
    url: '/clientserver.php/CustomerOrder/batchAudit',
    method: 'post',
    data: data
  });
}
// 取消订单列表
export function cancelOrderList(data) {
  return request({
    url: '/clientserver.php/CustomerOrder/getAuditStatusCancelPageList',
    method: 'post',
    data: data
  });
}
// 工作手机拨打
export function mobilePhone(data) {
  return request({
    url: '/clientserver.php/Call/mobilePhone',
    method: 'post',
    data: data
  });
}
// 添加微信
export function addWeChatFriend(data) {
  return request({
    url: '/clientserver.php/Call/addWeChatFriend',
    method: 'post',
    data: data
  });
}

// 批量修改数据类型 
export function batchModificationDataType(data) {
  return request({
    url: '/clientserver.php/MyCustomer/batchModificationDataType',
    method: 'post',
    data: data
  });
}

// 发送短信
export function sendSms(data) {
  return request({
    url: '/clientserver.php/MyCustomer/sendSms',
    method: 'post',
    data: data
  });
}

// 短信详情	
export function smsInfo(data) {
  return request({
    url: '/clientserver.php/MyCustomer/smsInfo',
    method: 'post',
    data: data
  });
}